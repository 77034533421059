import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box, Link, Stack, Button, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableNoData, TableHeadCustom } from 'src/components/table';

// ----------------------------------------------------------------------

export function NewOrdersTable({ title, subheader, tableData, headLabel, ...other }) {
  const { themeMode } = useSettingsContext();
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;
  return (
    <Card
      {...other}
      sx={{
        mt: 2,
      }}
    >
      {/* <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} /> */}
      <Box p={3} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{title}</Typography>
        <Button variant="outlined" size="small">
          <Link
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'none',
              },
            }}
            href={paths?.dashboard?.orders}
          >
            <Typography color={themeMode === 'dark' ? '#d3d3d3' : '#28282B'} variant="caption">
              View All
            </Typography>
          </Link>
        </Button>
      </Box>

      <Scrollbar sx={{ minHeight: 400 }}>
        <Table sx={{ minWidth: 640 }}>
          <TableHeadCustom headLabel={headLabel} />

          <TableBody>
            {tableData?.length > 0 ? (
              tableData?.map((row, key) => <RowItem key={key} row={row} />)
            ) : (
              <TableNoData
                notFound={notFound}
                sx={{
                  maxHeight: 310,
                }}
              />
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
}
NewOrdersTable.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.func,
  tableData: PropTypes.any,
  headLabel: PropTypes.any,
};

// ----------------------------------------------------------------------

function RowItem({ row }) {
  const {
    order_id,
    shipping_address,
    total_price,
    payment_type,
    order_status,
    is_paid,
    order_items,
    uid,
  } = row;

  const handleOrderStatus = () => {
    switch (order_status) {
      case 'PENDING':
        return {
          color: 'warning',
          label: 'Pending',
        };
      case 'PROCESSING':
        return {
          color: 'primary',
          label: 'Processing',
        };
      case 'PICKUP_SCHEDULED':
        return {
          color: 'info',
          label: 'Pickup Scheduled',
        };
      case 'SHIPPED':
        return {
          color: 'info',
          label: 'Shipped',
        };
      case 'DELIVERED':
        return {
          color: 'info',
          label: 'Delivered',
        };
      case 'CANCELLED':
        return {
          color: 'error',
          label: 'Cancelled',
        };
      case 'CANCEL_INITIATED':
        return {
          color: 'error',
          label: 'Cancel Initiated',
        };
      case 'RETURNED':
        return {
          color: 'error',
          label: 'Returned',
        };
      case 'RETURN_INITIATED':
        return {
          color: 'error',
          label: 'Return Initiated',
        };
      default:
        return {
          color: 'default',
          label: '',
        };
    }
  };
  return (
    <TableRow>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Link
          color="black"
          sx={{
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'none',
            },
            cursor: 'pointer',
          }}
          href={paths?.dashboard?.order_singleView(uid)}
        >
          <Typography variant="body2">{order_id}</Typography>
        </Link>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Typography variant="">{shipping_address?.name || '---'}</Typography>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Typography variant="body2">{order_items?.length}</Typography>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Label variant="soft" color={handleOrderStatus()?.color}>
          {handleOrderStatus()?.label}
        </Label>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle2">OMR</Typography>{' '}
          <Typography variant="body2"> &nbsp;{total_price || '---'}</Typography>
        </Stack>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Label variant="soft" color={payment_type === 'Online Payment' ? 'success' : 'info'}>
            {payment_type === 'COD' ? 'COD' : payment_type}
          </Label>
          <Label variant="soft" color={is_paid ? 'success' : 'error'}>
            {is_paid ? 'Paid' : 'Unpaid'}
          </Label>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

RowItem.propTypes = {
  row: PropTypes.any,
};
