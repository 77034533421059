import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createOrder,
  getAllOrder,
  deleteOrder,
  getOrderCounts,
  getOrderDetails,
  updateOrderDetails,
  getOrderCountStatic,
} from '../api/order';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    loading: false,
    error: {},
    order: {},
    staffDropList: [],
    orderDetails: {},
    orderCounts: {},
    orderCountStatic: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createOrder.pending]: (state) => {
      state.loading = true;
    },
    [createOrder.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.order;
      const newOrdersList = jsonState?.orders;

      const modifiedOrderList = {
        ...jsonState,
        orders: Array.isArray(newOrdersList)
          ? [action.payload, ...newOrdersList]
          : [action.payload],
      };
      state.loading = false;
      state.OrderDetails = action.payload;
      state.order = modifiedOrderList;
      state.error = {};
    },
    [createOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all order
    [getAllOrder.pending]: (state) => {
      state.loading = true;
    },
    [getAllOrder.fulfilled]: (state, action) => {
      state.loading = false;
      state.order = action.payload;
      state.error = {};
    },
    [getAllOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get order count
    [getOrderCounts.pending]: (state) => {
      state.loading = true;
    },
    [getOrderCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.orderCounts = action.payload;
      state.error = {};
    },
    [getOrderCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // static
    [getOrderCountStatic.pending]: (state) => {
      state.loading = true;
    },
    [getOrderCountStatic.fulfilled]: (state, action) => {
      state.loading = false;
      state.orderCountStatic = action.payload;
      state.error = {};
    },
    [getOrderCountStatic.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get single order details
    [getOrderDetails.pending]: (state) => {
      state.loading = true;
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.orderDetails = action.payload;
      state.error = {};
    },
    [getOrderDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update order details
    [updateOrderDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateOrderDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.OrderDetails = action.payload;
    //   state.error = {};
    // },
    [updateOrderDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.order;
      const modifiedOrderList = {
        ...jsonState,
        orders: jsonState.orders?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
      };

      state.loading = false;
      // state.order = modifiedOrderList;
      state.OrderDetails = action.payload;
      state.order = modifiedOrderList;
      state.error = {};
    },
    // [updateOrderDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.order;
    //   console.log(jsonState)
    //   const modifiedOrderList = {
    //     ...jsonState,
    //     orders: jsonState.orders?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.OrderDetails = action.payload;
    //   state.error = {};
    // },
    [updateOrderDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteOrder.pending]: (state) => {
      state.loading = true;
    },
    [deleteOrder.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.order;
      const modifiedOrderList = {
        ...jsonState,
        orders: jsonState.orders?.filter((order) => order.uid !== action.payload),
      };
      state.loading = false;
      state.order = modifiedOrderList;
      state.error = {};
    },
    [deleteOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = orderSlice.actions;

export default orderSlice.reducer;
