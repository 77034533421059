import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { Link, Stack, Button, Tooltip, IconButton, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import TextMaxLine from 'src/components/text-max-line';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export function OutOfStockProducts({ title, subheader, list, stockOutCount, ...other }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { themeMode } = useSettingsContext();

  const handleLink = () => {
    navigate(
      {
        pathname: paths?.dashboard?.inventory?.root, // Define the inventory path here
      },
      {
        state: {
          ...location.state,
          page: 0,
          sort: 'out_of_stock',
        },
      }
    );
  };

  return (
    <Card {...other}>
      <Box p={2.8} display="flex" alignItems="center" justifyContent="space-between">
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="h6">{title}</Typography>
          &nbsp; &nbsp;
          <Label variant="soft" color="error">
            {' '}
            {stockOutCount} Products
          </Label>
        </Stack>
        <Stack>
          <Button variant="outlined" size="small" onClick={handleLink}>
            <Link
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <Typography color={themeMode === 'dark' ? '#d3d3d3' : '#28282B'} variant="caption">
                View All
              </Typography>
            </Link>
          </Button>
        </Stack>
      </Box>

      <Scrollbar sx={{ minHeight: 380 }}>
        <Box
          sx={{
            py: 2,
            px: 3,
            // p: 3,
            gap: 3,
            minWidth: 360,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {list?.map((item, key) => (
            <Item key={key} item={item} />
          ))}
        </Box>
      </Scrollbar>
    </Card>
  );
}

OutOfStockProducts.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.func,
  list: PropTypes.any,
  stockOutCount: PropTypes.any,
};

function Item({ item, sx, ...other }) {
  const navigate = useNavigate();
  const onViewRow = () => {
    navigate(paths?.dashboard?.product_update(item?.uid));
  };
  return (
    <Box
      sx={{
        gap: 2,
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      {...other}
    >
      <Avatar
        variant="rounded"
        alt={item.images[0]?.public_url}
        src={item.images[0]?.url}
        sx={{ width: 48, height: 48, flexShrink: 0 }}
      />

      <Box
        sx={{ gap: 0.5, minWidth: 0, display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}
      >
        <Stack>
          <TextMaxLine line={2} sx={{ maxWidth: 250, textAlign: 'start' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '400', textAlign: 'start' }}>
              {item?.name?.english}
            </Typography>
          </TextMaxLine>
          <Typography color="#919EAB" variant="caption">
            {item?.sap_id}
          </Typography>
        </Stack>
      </Box>
      <Stack
        columnGap={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          color: 'text.secondary',
          typography: 'caption',
          mb: 0.5,
          border: '1px solid #919eab24',
          padding: 0.6,
          borderRadius: '14px',
          width: 'fit-content',
        }}
      >
        <Stack direction="row" alignItems="center">
          <Tooltip title="View product">
            <IconButton
              color="info"
              onClick={onViewRow}
              // onClick={() => handleViewClick(uid)}

              sx={{ borderRadius: '6px' }}
            >
              <Iconify icon="eva:eye-outline" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Box>
  );
}

Item.propTypes = {
  item: PropTypes.any,
  sx: PropTypes.any,
};
