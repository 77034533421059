import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from '../http';
import { activeSnack } from '../store/common';

export const createOrder = createAsyncThunk(
  'order/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/order/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Order Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllOrder = createAsyncThunk(
  'order/list',
  async ({ page, search, dispatch, limit, state, date }) => {
    try {
      const response = await post(
        `/order/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&from=${date?.from || ''}&to=${date?.to || ''}`,
        state
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getOrderCounts = createAsyncThunk('orders/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/order/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const getOrderCountStatic = createAsyncThunk(
  'orders/count/static',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/order/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getOrderDetails = createAsyncThunk('order/single', async ({ orderId, dispatch }) => {
  try {
    const response = await get(`/order/admin/single/${orderId}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateOrderDetails = createAsyncThunk(
  'order/update',
  async ({ state, orderId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/order/admin/${orderId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Order Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  'order-status/update',
  async ({ state, orderId, dispatch, handleClose }) => {
    try {
      const URL = `/order/admin/status/update`;

      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Status Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteOrder = createAsyncThunk(
  'order/delete',
  async ({ orderId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/order/admin/${orderId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Order deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
