import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  deleteSection,
  createSection,
  getAllSections,
  archiveSection,
  restoreSection,
  getSectionCounts,
  getSectionDetails,
  updateSectionStatus,
  updateSectionDetails,
} from '../api/sections';

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState: {
    loading: false,
    error: {},
    sections: {},
    sectionCounts: {},
    sectionsDetails: {},
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearProductPagesError: (state) => {
      state.error = true;
    },
    clearPagesDetailsState: (state) => {
      state.sectionsDetails = {};
    },
  },
  extraReducers: {
    // create
    [createSection.pending]: (state) => {
      state.loading = true;
    },
    [createSection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedDistrictList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.sections = modifiedDistrictList;
      state.error = {};
    },
    [createSection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all list
    [getAllSections.pending]: (state) => {
      state.loading = true;
    },
    [getAllSections.fulfilled]: (state, action) => {
      state.loading = false;
      state.sections = action.payload;
      state.error = {};
    },
    [getAllSections.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get sections count
    [getSectionCounts.pending]: (state) => {
      state.loading = true;
    },
    [getSectionCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.sectionCounts = action.payload;
      state.error = {};
    },
    [getSectionCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // singleView
    [getSectionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSectionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.sectionsDetails = action.payload;
      state.error = {};
    },
    [getSectionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update
    [updateSectionDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductPagesDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductLabelDetails = action.payload;
    //   state.error = {};
    // },
    [updateSectionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.sectionsDetails = action.payload;
      state.sections = action.payload;
      state.error = {};
    },

    [updateSectionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update status
    [updateSectionStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [updateSectionStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sections;
      const modifiedSectionPagesList = {
        ...jsonState,
        sections: jsonState?.sections?.map((item) =>
          item?.uid === action.payload?.uid
            ? { ...item, is_active: action.payload?.is_active }
            : item
        ),
      };
      state.statusLoading = false;
      state.sections = modifiedSectionPagesList;
      state.error = {};
    },

    [updateSectionStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // archive
    [archiveSection.pending]: (state) => {
      state.delLoading = true;
    },
    // archive
    [archiveSection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sections;
      const modifiedProductList = {
        ...jsonState,
        sections: jsonState.sections?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.sections = modifiedProductList;
      state.error = {};
    },
    [archiveSection.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore

    [restoreSection.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreSection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sections;
      const modifiedPagesList = {
        ...jsonState,
        sections: jsonState.sections?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.sections = modifiedPagesList;
      state.error = {};
    },
    [restoreSection.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // delete
    [deleteSection.pending]: (state) => {
      state.loading = true;
    },
    [deleteSection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sections;
      const modifiedDistrictList = {
        ...jsonState,
        sections: jsonState.sections?.filter((sections) => sections.uid !== action.payload),
      };
      state.loading = false;
      state.sections = modifiedDistrictList;
      state.error = {};
    },
    [deleteSection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductPagesError, clearPagesDetailsState } = sectionsSlice.actions;

export default sectionsSlice.reducer;
