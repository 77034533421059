import { createSlice } from '@reduxjs/toolkit';

// import { covertToJSON } from '../../utils/stateToJson';
import {
  getSettingsDetails,
  updateSettingsDetails,
  getSiteSettingsDataCounts,
} from '../api/settings';

export const settings = createSlice({
  name: 'settings',
  initialState: {
    loading: false,
    error: {},
    settingsDetails: {},
    siteSettingsCount: {},
    updateLoading: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get settings details
    [getSettingsDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSettingsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.settingsDetails = action.payload;
      state.error = {};
    },
    [getSettingsDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update settings details
    [updateSettingsDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSettingsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.settingsDetails = action.payload;
      state.error = {};
    },
    [updateSettingsDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getSiteSettingsDataCounts.pending]: (state) => {
      state.loading = true;
    },
    [getSiteSettingsDataCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.siteSettingsCount = action.payload;
      state.error = {};
    },
    [getSiteSettingsDataCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = settings.actions;

export default settings.reducer;
